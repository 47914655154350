<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">Pacote de Mídias</h2>
    <v-col cols="12" id="actions" class="w-full d-flex justify-end">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            fab
            color="primary"
            class="mr-2 ma-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menu" :key="item.id" @click="cadastro()">
            <v-list-item-title class="ml-2">{{
              item.descricao
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-row cols="12" class="d-flex w-full justify-center">
      <midia-card
        v-for="item in pacotes"
        :key="item.id"
        :pacote="item"
        @midias="openMidia(item)"
        @editar="openEditar(item)"
        class="mr-2 mb-4"
      />
    </v-row>

    <v-dialog
      v-model="midiaDialog"
      fullscreen
      persistent
      transition="dialog-transition"
    >
      <cadastro-midias
        :id="pacoteId"
        :key="midiaCounter"
        @close="handleClose()"
        @saveMidia="this.$router.go()"
      />
    </v-dialog>
    <v-dialog
      v-model="pacoteDialog"
      fullscreen
      persistent
      transition="dialog-transition"
    >
      <cadastro-pacote
        :key="counter"
        :editing="true"
        :pacoteMidia="pacoteMidia"
        @close="pacoteDialog = false"
        @reload="getAllMidias()"
      />
    </v-dialog>
    <v-dialog
      v-model="midiaSelectDialog"
      fullscreen
      persistent
      transition="dialog-transition"
    >
      <pacote-midias
        :imagens="imagens"
        :id="pacoteId"
        :key="midiaCounter"
        :situacao="situacaoPacote"
        @newMidias="handleNewMidias()"
        @reload="getAllMidias()"
        @close="handleCloseMidias()"
      />
    </v-dialog>
    <v-dialog v-model="loadingMidia" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Porfavor, aguarde
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import midiaCard from "./components/midiaCard.vue";
import cadastroMidias from "./cadastroMidias.vue";
import cadastroPacote from "./cadastroPacote.vue";
import PacoteMidia from "@/services/PacoteMidia";
import PacoteMidias from "./PacoteMidias.vue";

export default {
  components: {
    midiaCard,
    cadastroMidias,
    cadastroPacote,
    PacoteMidias,
  },

  data() {
    return {
      loadingMidia: false,
      loading: false,
      counter: 0,
      currentPage: 0,
      midiaCounter: 0,
      page: 1,
      midiaDialog: false,
      midiaSelectDialog: false,
      pacoteDialog: false,
      pacoteId: 0,
      pacoteMidia: {},
      pacotes: [],
      imagens: [],
      situacaoPacote: 0,
      menu: [{ descricao: "Cadastrar Novo Pacote", id: 1 }],
    };
  },

  created() {
    this.getAllMidias();
  },

  methods: {
    isVod() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaVod;
    },

    handleCloseMidias(){
      this.getAllMidias()
      this.midiaSelectDialog = false
    },

    handleNewMidias(){
      this.getAllMidias()
      this.midiaSelectDialog = false
      this.midiaDialog = true
    },

    cadastro() {
      this.$router.push(`/cadastro-midias`);
    },

    openEditar(item) {
      this.counter += 1;
      this.pacoteMidia = item;
      this.pacoteDialog = true;
    },

    getAllMidias() {
      this.loadingMidia = true;
      PacoteMidia.getAllMidias()
        .then((response) => {
          this.pacotes = response.data.content.map((i) => ({
              dataLiberacao: i.dataLiberacao,
              id: i.id,
              descricao: i.descricao,
              instalacaoId: i.instalacaoId,
              ...(i.midiaCapa && {midiaCapa: {
                id: i.midiaCapa?.id,
                isCapa: i.midiaCapa?.isCapa,
                midias:{
                  id: i.midiaCapa?.midias?.id,
                  instalacaoId: i.midiaCapa?.midias?.instalacaoId,
                  midiaUrl: i.midiaCapa?.midias?.midiaUrl,
                  tpMidia: i.midiaCapa?.midias?.tpMidia,
                ...(i.midiaCapa?.midias?.tpMidia == "VIDEO" && { isVod: this.isVod() }),
                }},
              }),
              observacao: i.observacao,
                quantidadeUsuario: i.quantidadeUsuario,
                registros: i.registros,
                situacaoPacote: i.situacaoPacote,
            }));
        })
        .catch((err) => {
          console.log(err);
          this.loadingMidia = false;
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    openMidia(item) {
      this.pacoteId = item.id;
      this.situacaoPacote = item.situacaoPacote;
      this.midiaCounter += 1;
      PacoteMidia.getMidiasInPacote(item.id)
        .then((response) => {
          let midias = response.data;
          if (midias.length < 1) {
            this.midiaDialog = true;
          } else {
            this.imagens = response.data.map((i) => ({
              id: i.id,
              isCapa: i.isCapa,
              midias: {
                id: i.midias.id,
                instalacaoId: i.midias.instalacaoId,
                midiaUrl: i.midias.midiaUrl,
                tpMidia: i.midias.tpMidia,
                ...(i.midias.tpMidia == "VIDEO" && { isVod: this.isVod() }),
              },
            }));
            this.midiaSelectDialog = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next() {
      this.currentPage += 8;
    },
    previous() {
      this.currentPage -= 8;
    },
    handleClose() {
      this.getAllMidias()
      this.midiaDialog = false;
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
