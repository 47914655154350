<template>
    <div>
        <v-card
        max-width="374"
        >
        <div class="pa-2 d-flex w-full justify-space-between">
            <span class="text-subtitle-2"> {{ pacote.situacaoPacote | filtrarSituacao }}</span>
            <span v-if="pacote.dataLiberacao" class="text-subtitle-2"> {{ pacote.dataLiberacao | filtraDH }}</span>
        </div>
        
        <v-img
            v-if="!pacote.midiaCapa"
            height="250"
            src="@/assets/no-image-placeholder.svg"
        ></v-img>
        <v-img
            v-if="pacote.midiaCapa && pacote.midiaCapa.midias.tpMidia == 'IMAGEM'"
            height="250"
            :src="pacote.midiaCapa.midias.midiaUrl"
        ></v-img>
        <video-post
            v-if="pacote.midiaCapa && pacote.midiaCapa.midias.tpMidia == 'VIDEO'"
            :payload="pacote.midiaCapa.midias"
            :height="250"
            :width="374"
        />
        <div class="ma-2">
            <span> {{ pacote.descricao }} </span>
        </div>

        <v-card-actions>
            <v-btn color="primary" rounded class="px-2" @click="openEditar()"> <span class="px-2"> Editar </span> </v-btn>
            <v-btn color="primary" rounded class="mr-4" @click="openMidias()"> <span class="px-2"> Midias </span> </v-btn>
            <span class="mx-2"><v-icon> mdi-calendar</v-icon> {{ pacote.registros }}</span>
            <span><v-icon> mdi-account-group</v-icon> {{ pacote.quantidadeUsuario }} </span> 
        </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import moment from "moment";
import videoPost from "../../post/videoComp";

export default {
    props: {
        pacote: {
            type: [Object, Array],
            default: () => {}
        },

    },

    components: {
        videoPost
    },

    data() {
        return {}
    },

    filters: {
        filtraDH(val) {
        if (val) {
            return moment(val).format("DD/MM/YYYY");
        } else {
            return "Inválido";
        }
        },
        filtrarSituacao(val){
        if(val){
            if(val == 1) return "Cadastrado"
            if(val == 2) return "Liberado"
            if(val == 5) return "Publicado"
            if(val == 3) return "Bloqueado"
            if(val == 4) return "Cancelado"
            if(val == 9) return "Encerrado"
        } else {
            return "Inválido";
        }
        }
    },

    methods: {
        openMidias(){
            this.$emit("midias")
        },

        openEditar(){
            this.$emit('editar')
        }
    }
}
</script>