<template>
  <v-card>
    <v-toolbar elevation="1" flat>
      <v-toolbar-title>
        <span class="headline">Mídias do Pacote</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose()">mdi-arrow-left</v-icon>
    </v-toolbar>
    <v-divider />
    <v-col cols="12" id="actions" class="w-full d-flex justify-end">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small fab color="primary" class="mr-2 ma-1" :disabled="situacao != 1 && situacao != 2" v-bind="attrs"
            v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menu" :key="item.id" @click="cadastroMidias()">
            <v-list-item-title class="ml-2">{{
              item.descricao
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn small fab :disabled="situacao != 1 && situacao != 2" color="primary" class="mr-2 ma-1"
        @click="excluirImagens()">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex flex-wrap justify-center flex-row">
      <div class="w-full d-flex flex-wrap">
        <div v-for="(imagem, i) in imagens" :key="i">
          <v-checkbox v-model="selectedImages" :value="imagem" multiple :disabled="(situacao != 1 && situacao != 2)">
            <template v-slot:label>
              <div class="d-flex flex-wrap">
                <v-img v-if="imagem.midias.tpMidia == 'IMAGEM'" height="250" max-width="374"
                  :src="imagem.midias.midiaUrl"></v-img>
                <video-post v-if="imagem.midias.tpMidia == 'VIDEO'" :payload="imagem.midias" :height="250"
                  :width="374" />
              </div>
            </template>
          </v-checkbox>
          <v-row class="w-full">
            <v-icon v-if="imagem.isCapa" color="primary" class="ml-8">
              mdi-star
            </v-icon>
            <span v-if="imagem.isCapa"> Esse item é a capa do pacote!</span>
          </v-row>
          <v-row v-if="!imagem.isCapa" class="align-center">
            <v-btn icon class="ml-8" @click="saveCapa(imagem)" :disabled="situacao == 2 || situacao != 1">
              <v-icon> mdi-star </v-icon></v-btn>
            <span v-if="!imagem.isCapa"> Definir como capa</span>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-dialog v-model="midiaDialog" fullscreen persistent transition="dialog-transition">
      <cadastro-midias :id="id" :key="counterList" @close="handleClose()" @reload="handleReload()" />
    </v-dialog>
    <v-dialog v-model="loadingCapa" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Porfavor, aguarde
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { PacoteMidiasService } from "@/services";
import cadastroMidias from "./cadastroMidias.vue";
import videoPost from "../post/videoComp";

export default {
  components: {
    cadastroMidias,
    videoPost,
  },

  props: {
    imagens: {
      type: Array,
      default: () => { },
    },
    id: {
      type: Number,
      default: 0,
    },
    situacao: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      radioImagens: null,
      menu: [{ descricao: "Inserir Novas Mídias", id: 1 }],
      midiaDialog: false,
      loadingCapa: false,
      counterList: 0,
      selectedImages: [],
    };
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },

    handleReload() {
      this.$emit("reload");
    },

    saveCapa(item) {
      this.loadingCapa = true
      PacoteMidiasService.setCapaPacote(item.id)
        .then((response) => {
          console.log(response);
          this.$root.showSucesso("Capa definida com sucesso");
          this.$emit("reload");
          this.handleClose();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
          this.loadingCapa = false
        });
    },

    cadastroMidias() {
      this.$emit('newMidias')
    },


    excluirImagens() {
      this.loadingMidia = true;
      if (this.selectedImages.some((i) => i.isCapa == true)) return this.$root.showErro('Não é possível excluir a capa');
      PacoteMidiasService.deleteImages(this.selectedImages)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.$emit("reload");
          this.handleClose();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },
  },
};
</script>

<style lang="scss">
.upload-block {
  width: 80vw;
  height: 30vh;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;

  &:hover {
    border-color: #c11718;
  }
}

.hover {
  border-color: #c11718;
}

.animateIcon {
  transition: all 0.6s ease;
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
  flex-wrap: wrap;
}
</style>
