<template>
  <v-card>
    <v-toolbar elevation="1" flat>
      <v-toolbar-title>
        <span class="headline">Upload de mídias</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose()">mdi-arrow-left</v-icon>
    </v-toolbar>
    <v-divider />

    <v-col cols="12">
      <div class="d-flex w-full h-screen items-center justify-center text-center" id="app">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Solte as Imagens</h3>
        </div>
        <v-col cols="12">
          <table class="table table-hover">
            <thead>
              <tr class="border-divider">
                <th class="text-subtitle-2">#</th>
                <th class="text-subtitle-2">Thumb</th>
                <th class="text-subtitle-2">Nome</th>
                <th class="text-subtitle-2">Tamanho</th>
                <th class="text-subtitle-2">Progresso</th>
                <th class="text-subtitle-2">Status</th>
                <th class="text-subtitle-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!files.length">
                <td colspan="9">
                  <div class="text-center p-5">
                    <h3 class="mb-1">Arraste e jogue para upload<br />ou</h3>
                    <label :for="name" color="primary" class="button-upload mt-4">
                      Selecione as Mídias
                    </label>
                  </div>
                </td>
              </tr>
              <tr v-for="(file, index) in files" :key="file.id">
                <td>{{ index + 1 }}</td>
                <td>
                  <img class="td-image-thumb" v-if="file.blob && file.type.includes(`image`)" :src="file.blob" />
                  <video v-else-if="file.blob && file.type.includes(`video`)" class="td-image-thumb" no-controls>
                    <source :src="file.blob" :type="file.type" />
                  </video>
                  <span v-else>Sem Thumb Disponivel</span>
                </td>
                <td>
                  <div class="filename">
                    {{ file.name }}
                  </div>
                  <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <v-progress-linear v-if="!file.error" color="light-blue" height="5" :value="file.progress"
                      striped></v-progress-linear>
                    <v-progress-linear v-if="file.error" color="red" height="5" :value="file.progress"
                      striped></v-progress-linear>
                  </div>
                </td>
                <td>{{ file.size | formatBytes }}</td>
                <td>{{ file.progress }} %</td>

                <td v-if="file.error && file.size < size" class="red--text text--lighten-1">
                  Falha
                </td>
                <td v-if="file.error && file.size > size" class="red--text text--lighten-1">
                  Arquivo maior que 200MB
                </td>
                <td v-else-if="file.success">Sucesso</td>
                <td v-else-if="file.active">em progresso</td>
                <td v-else></td>
                <td>
                  <div class="btn-group">
                    <div class="d-flex justify-center">
                      <v-btn icon class="dropdown-item" href="#" v-if="file.active" @click.prevent="
                        $refs.upload.update(file, { active: false })
                        "><v-icon color="error">mdi-cloud-off-outline</v-icon></v-btn>
                      <v-btn icon class="dropdown-item" href="#" v-else-if="
                        file.error &&
                        file.error !== 'compressing' &&
                        file.error !== 'image parsing' &&
                        $refs.upload.features.html5
                      " @click.prevent="
                          $refs.upload.update(file, {
                            active: true,
                            error: '',
                            progress: '0.00',
                          })
                          "><v-icon>mdi-cloud-sync</v-icon></v-btn>
                      <v-btn icon :class="{
                        'dropdown-item': true,
                        disabled:
                          file.success ||
                          file.error === 'compressing' ||
                          file.error === 'image parsing',
                      }" href="#" v-else @click.prevent="
                          file.success ||
                            file.error === 'compressing' ||
                            file.error === 'image parsing'
                            ? false
                            : $refs.upload.update(file, { active: true })
                          "><v-icon>mdi-cloud-upload</v-icon></v-btn>
                      <div class="dropdown-divider"></div>
                      <v-btn icon class="dropdown-item" href="#" @click.prevent="removeFile(file)"><v-icon
                          color="error"> mdi-delete </v-icon></v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <VueUploadComponent class="btn btn-primary dropdown-toggle" :extensions="extensions" :accept="accept"
            :custom-action="uploadFile" :multiple="multiple" :maximum="99" :directory="directory"
            :create-directory="createDirectory" :size="size || 0" :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :data="data" :drop="drop" :drop-directory="dropDirectory" :add-index="addIndex" v-model="files"
            @input-filter="inputFilter" @input-file="inputFile" ref="upload">
          </VueUploadComponent>
        </v-col>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-end w-full mt-4">
      <v-progress-linear v-if="loadingMidia" indeterminate color="primary" class="mb-0" />
      <v-btn v-if="!loadingMidia" color="primary" @click="salvarImagens()">
        Salvar
      </v-btn>
    </v-col>
    <v-dialog v-model="loadingMidia" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Porfavor, aguarde
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { PacoteMidiasService } from "@/services";
//import videoPost from "../post/videoComp";
import VueUploadComponent from "vue-upload-component";

export default {
  components: {
    //videoPost,
    VueUploadComponent,
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      files: [],
      accept:
        "image/png,image/gif,image/jpeg,image/webp,video/mp4, video/mov, video/wmv, video/avi, video/flv",
      extensions: "gif,jpg,jpeg,png,webp,mp4,mov,wmv,avi,flv",
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: false,
      minSize: 10,
      size: 41943040 * 5,
      addIndex: false,
      thread: 4,
      name: "file",
      postAction: "/upload/post",
      putAction: PacoteMidiasService.uploadMidiasPacote(),
      data: {
        files: this.files,
      },
      uploadAuto: true,
      isOption: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: "",
      },
      editFile: {
        show: false,
        name: "",
      },
      formData: {},
      loadingMidia: false,
      imagesList: [],
    };
  },

  filters: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
  },

  beforeMount() {
    this.reload += 1;
  },

  methods: {
    async uploadFile(file) {
      this.formData = new FormData();
      this.formData.append("file", file.file);
      this.formData.append("name", file.name);
      file.progress = "30.00";
      const res = await PacoteMidiasService.uploadMidiasPacote(this.formData);
      if (res) {
        file.progress = "100.00";
        let dataToAdd = res.data.shift();
        this.imagesList.push(dataToAdd);
      }
    },

    removeFile(file) {
      this.imagesList.splice(file, 1);
      this.$refs.upload.remove(file);
    },

    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        console.log(this.$refs.upload);
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            console.log("Teste size");
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },

    handleClose() {
      this.$emit("close");
    },

    isVod() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaVod;
    },

    onChange(event) {
      this.filelist = event.target.files;
      event.preventDefault();
      this.dragging = false;
      this.uploadMessage = `Clique ou se preferir arraste e solte os arquivos desejados`;
      this.formData = new FormData();
      for (let i = 0; i < event.target.files.length; i++) {
        this.formData.append("file", this.filelist[i]);
        this.formData.append("name" + i, this.filelist[i].name);
      }
      PacoteMidiasService.uploadMidiasPacote(this.formData)
        .then((response) => {
          let elementArray = [];
          elementArray = response.data;
          this.imagesList = this.filesToUpload.concat(
            this.imagesList,
            elementArray
          );
          this.reload += 1;
          this.isUploaded = true;
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => { });
    },

    remove(i) {
      this.imagesList.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Animação e alteração de texto
      this.uploadMessage = "Solte os arquivos aqui";
      this.dragging = true;
    },
    dragleave(event) {
      // Clean up
      event.preventDefault();
      this.uploadMessage = `Clique ou se preferir arraste e solte os arquivos desejados`;
      this.dragging = false;
    },
    salvarImagens() {
      let payload = [];
      for (let i = 0; i < this.imagesList.length; i++) {
        let objectPayload = {
          isCapa: false,
          pacoteId: this.id,
          midias: {
            midiaUrl: this.imagesList[i].midiaUrl,
            tpMidia: this.imagesList[i].tpMidia,
            ...(this.imagesList[i].tpMidia == "VIDEO" && {
              isVod: this.isVod(),
            }),
          },
        };
        payload.push(objectPayload);
      }

      PacoteMidiasService.insertMidia(payload)
        .then(() => {
          this.$root.showSucesso("Sucesso");
          this.handleClose();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => { });
    },
  },
};
</script>

<style lang="scss">
.button-upload {
  padding: 0.5em;
  cursor: pointer !important;
  background-color: var(--v-primary-base);
  color: #fff;
  border-radius: 5px;
}

.table {
  width: 100%;
  height: 20vh;
}

.upload-block {
  width: 80vw;
  height: 30vh;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;

  &:hover {
    border-color: #c11718;
  }
}

.hover {
  border-color: #c11718;
}

.animateIcon {
  transition: all 0.6s ease;
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.no-bullet {
  list-style-type: none;
}

.image-container {
  width: 350px !important;
}

.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}

.drop-active {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
